<template>
  <b-tabs pills>
    <b-tab title="Chat" active>
     <b-card-text class="pt-2 text-center">
        <feather-icon icon="MessageCircleIcon" size="56" /><br />
        Chat is disabled for this stage.
      </b-card-text>
    </b-tab>
    <b-tab title="Sessions">
      <b-card-text class="pt-2 text-center">
        <feather-icon icon="CalendarIcon" size="56" /><br />
        No more sessions scheduled on this stage.
      </b-card-text>
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab, BCard, BCardText } from "bootstrap-vue";
import Chat from "@/layouts/components/Chat.vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";

export default {
  name: "StageSidebar",
  components: {
    BTabs,
    BCard,
    BCardText,
    BTab,
    Chat,
    FeatherIcon,
  },
  data() {
    return {
      data: {
        userAvatar: require("@/assets/images/avatars/8.png"),
        messages: [
          {
            message: "Hi",
            time: new Date().toLocaleString(),
            senderId: 11,
            senderName: "Rich Brown",
            senderTitle: "",
          },
          {
            message: "Hello. How can I help You?",
            time: new Date().toLocaleString(),
            senderId: 2,
            senderName: "Anil Kant",
            senderTitle: "",
          },
          {
            message:
              "Can I get details of my last transaction I made last month?",
            time: new Date().toLocaleString(),
            senderId: 5,
            senderName: "Mary Dutz",
            senderTitle: "",
          },
          {
            message: "We need to check if we can provide you such information.",
            time: new Date().toLocaleString(),
            senderId: 2,
            senderName: "Anil Kant",
            senderTitle: "",
          },
          {
            message: "I will inform you as I get update on this.",
            time: new Date().toLocaleString(),
            senderId: 2,
            senderName: "Anil Kant",
            senderTitle: "",
          },
          {
            message: "If it takes long you can mail me at my mail address.",
            time: new Date().toLocaleString(),
            senderId: 11,
            senderName: "Rich Brown",
            senderTitle: "",
          },
        ],
      },
    };
  },
};
</script>
 