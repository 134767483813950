<template>
    <div class="d-flex justify-content-center shadow-lg border-primary" style="border-radius:10px;">
        <b-button variant="primary" v-if="playerIsMuted" @click="unmute" id="btn-unmute"><font-awesome-icon :icon="['fal', 'volume-slash']"/> Unmute</b-button>
        <video ref="videoPlayer" class="video-js vjs-theme-default vjs-big-play-centered vjs-16-9" width="auto" height="auto"></video>
    </div>
</template>

<script>
import videojs from 'video.js';
import {BButton} from 'bootstrap-vue'

export default {
    name: "VideoPlayer",
    components: {
        BButton
    },
    props: {
        options: {
            type: Object,
            default() {
                return { autoplay: false, muted: false, controls: true, poster: (this.$store.state.event.event.meta.videoBanner || ''), sources: [] }
            }
        },
        url: {
            type: String,
            default() {
                return ''
            }
        }
    },
    data() {
        return {
            player: null,
            playerIsMuted: false,
        }
    },
    computed: {
        playerOptions() {
            let _options = this.options
            // console.log('this.options', this.options)
            if ((!this.options || this.options.sources.length===0) && this.url) {
                _options = { 
                    autoplay: false, 
                    muted: false,
                    controls: true, 
                    preload: 'auto',
                    sources: [ { type: "video/mp4", src: this.url+'#t=0.1' } ] 
                }
            }
            return _options
        } 
    },
    methods: {
        unmute() {
            if (this.player) {
                this.player.muted(false)
                this.playerIsMuted = false
            }
        },
    },
    mounted() {
        this.player = videojs(this.$refs.videoPlayer, this.playerOptions, function onPlayerReady() {
            // console.log('onPlayerReady', this.playerOptions);
        })
        this.player.on('error', function(e) {
            // console.log('player error', e)
        })
        // console.log('this.playerOptions', this.playerOptions);
        if (this.playerOptions.autoplay && this.player.paused()) {
            this.playerIsMuted = true
            this.player.muted(true)
            this.player.play()
        }
        // this.player.responsive = true;
    },
    beforeDestroy() {
        if (this.player) {
            this.player.dispose()
        }
    }
}
</script>
<style>
    .vjs-control-bar {
        border-bottom-left-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
    }
    /* .vjs-play-control button {
        border-bottom-left-radius: 20px !important;
    } */
    .video-js video, .vjs-poster {
        border-radius: 10px;
    }
    .video-js.vjs-16-9 {
        padding-top: 56.25%;
        width: 100%;
        border-radius: 10px;
    }
    #btn-unmute {
        position: absolute;
        top: 15px;
        right: 15px;
        z-index: 99;
        border-top-left-radius: 1em;
        border-bottom-left-radius: 1em;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
</style>