<template>
  <div>
    <b-row>
      <b-col md="12" lg="8">
         <b-card class="mb-0 title-card">
          <b-card-text>
            <b-row class="d-flex flex-row justify-content-start">
              <b-col>
                <feather-icon :icon="VideoIcon" />
                <span class="h3">Global Game Changer Awards</span>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
        <b-media>
          <VideoPlayer :options="data.videoOptions" />
        </b-media>
        <!-- Speaker Cards -->
        <b-row align-h="between" v-if="data.speakers">
          <b-col><h2>Speakers</h2></b-col>
        </b-row>
        <b-row class="match-height">
          <b-col
            lg="4"
            md="6"
            v-for="speaker in data.speakers"
            :key="speaker.id"
          >
            <speaker-card :data="speaker" />
          </b-col>
        </b-row>
        <!--/ Speaker Cards -->

        <!-- Supporter Cards -->
        <b-row align-h="between" v-if="data.supporters">
          <b-col><h2>Supporters</h2></b-col>
        </b-row>
        <b-row class="match-height">
          <b-col
            lg="4"
            md="6"
            v-for="supporter in data.supporters"
            :key="supporter.id"
          >
            <supporter-card :data="supporter" />
          </b-col>
        </b-row>
        <!--/ Supporter Cards -->

      </b-col>
      <!-- Chat/Q&A/Poll Sidebar-->
      <b-col md="12" lg="4">
        <stage-sidebar />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BImg,
  BCarousel,
  BCarouselSlide,
  BRow,
  BCol,
  BMedia,
} from "bootstrap-vue";
import { $themeConfig } from "@themeConfig";
import VideoPlayer from "@/layouts/components/VideoPlayer.vue";
import StageSidebar from "./StageSidebar2.vue";
import SpeakerCard from "@/layouts/components/SpeakerCard.vue";
import SupporterCard from "@/layouts/components/SupporterCard.vue";
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';

export default {
  metaInfo: {
    title: 'Stage',
  },
  components: {
    BCard,
    BCardText,
    BLink,
    BImg,
    BCarousel,
    BCarouselSlide,
    BRow,
    BCol,
    BMedia,
    VideoPlayer,
    StageSidebar,
    SpeakerCard,
    SupporterCard,
    FeatherIcon,
  },
  data() {
    //   https://ll-hls-test.apple.com/cmaf/master.m3u8
    return {
      data: {
        videoOptions: {
          autoplay: true,
          controls: true,
          sources: [
            {
              src: "https://hummzspaces.s3.amazonaws.com/3c6qGJaLZSfk3xK9Mu7~RuZPr4QgQTa~vgNqwWT8rECSMrBWlBPeaiojdF4i/events/JQ7v1mhGGUo8oSIlj3idS7/videos/social-speakers.mp4",
              type: "video/mp4",
            },
          ],
        },
        supporters: [
          {
            id: 1,
            avatar: require("@/assets/images/exhibitors/goldtalentnomicslogo.jpg"),
            fullName: "TalentNomics Inc",
            description: "Reimagine leadership for modern workplaces in the global economy by developing and leveraging women leaders’ talents to their fullest potential and eliminating the gender gap in leadership and pay."
          },
        ]
      },
    };
  },
  setup() {
    // App Name
    const { appLogoImage } = $themeConfig.app;
    return {
      appLogoImage,
    };
  },
};
</script>

<style>
.title-card .card-body {
   padding: 0.7rem 1.5rem;
}
</style>
